
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OpenSourceAppItem',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  }
})
